<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <BaseTable
      ref="table"
      :headers="headers"
      :items="companies"
      :status="false"
      :delete_meg="'حذف الشركة ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_company($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
    ></BaseTable>
    <!--table -->

    <BaseForm
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(type_id = null), (title = 'اضافة شركة')"
      @close_snack="close_snack($event)"
    ></BaseForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import BaseTable from "@/components/base/table";
import BaseForm from "@/components/base/form";

export default {
  name: "companies",

  data() {
    return {
      delete_dialog: false,
      company_id: null,
      delete_loader: false,
      companies: [],
      title: "اضافة شركة",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      form_obj: {
        company_name: {
          value: "",
          label: "اسم الشركة",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        owner_name: {
          value: "",
          label: "اسم صاحب الشركة",
          type: "text",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        type: {
          value: "",
          label: "نوع الشركة",
          type: "select",
          items: [],
          item_text: "type_name",
          item_value: "id",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        phone: {
          value: "",
          label: "رقم الهاتف",
          type: "number",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        phone_2: {
          value: "",
          label: "رقم الهاتف ٢",
          type: "number",
        },
        fax: {
          value: "",
          label: "رقم الفاكس",
          type: "number",
        },
        country: {
          value: "",
          label: "البلد",
          type: "text",
        },
        address: {
          value: "",
          label: "العنوان",
          type: "text",
        },
      },
      headers: [
        {
          value: "company_name",
          text: "اسم الشركة",
          align: "center",
        },
        {
          value: "owner_name",
          text: "صاحب الشركة",
          align: "center",
        },
        {
          value: "wallet_count",
          text: "المحفظة",
          align: "center",
        },
        {
          value: "phone",
          text: "رقم الهاتف",
          align: "center",
        },
        {
          value: "phone_2",
          text: "رقم الهاتف 2",
          align: "center",
        },
        {
          value: "type",
          text: "النوع",
          align: "center",
          sec_val: "type_name",
        },
        {
          value: "fax",
          text: " رقم الفاكس",
          align: "center",
        },
        {
          value: "country",
          text: "البلد",
          align: "center",
        },
        {
          value: "address",
          text: "العنوان",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },
  methods: {
    get_types() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "types",
            method: "get",
          },
        })
        .then((res) => {
          this.form_obj.type.items = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_companies() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "companies",
            method: "get",
          },
        })
        .then((res) => {
          console.log(res);
          this.companies = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.company_id ? `companies/${this.company_id}` : `companies`,
            method: this.company_id ? "put" : "post",
          },
          data: {
            company_name: this.form_obj.company_name.value,
            owner_name: this.form_obj.owner_name.value,
            type: this.form_obj.type.value,
            fax: this.form_obj.fax.value,
            country: this.form_obj.country.value,
            address: this.form_obj.address.value,
            phone: this.form_obj.phone.value,
            phone_2: this.form_obj.phone_2.value,
          },
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة شركة بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_companies();
        });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      this.title = "تعديل الشركة";
      Object.keys(ev.item).forEach((e) => {
        if (this.form_obj[e]) {
          if (e != "type") {
            this.form_obj[e].value = ev.item[e];
          } else {
            this.form_obj[e].value = ev.item[e].id;
          }
        }
      });
      this.company_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_company(type_id) {
      this.delete_dialog = false;
      if (type_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `companies/${type_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الشركة";
            this.delete_loader = false;
            this.get_companies();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          });
      }
    },
  },
  created() {
    this.get_companies();
    this.get_types();
  },
  components: {
    BaseTable,
    BaseForm,
  },
};
</script>
